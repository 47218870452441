
          $font-path: "/Users/harsh/Development/Projects/holotrak/01-code/github/synced-holotrak-web/src/assets/fonts";
        
$fontFamily: 'SFProDisplay';

//Colors Starts
$colorTransparent: transparent;

$colorGreyDark1: #3D4A57;
$colorGreyDark2: #7E7E7E;
$colorGreyDark3: #979797;
$colorGreyDark4: #D1D1D1;
$colorGreyDark5: #D6D6D6;
$colorGreyDark6: #DBDFE3;
$colorGrey: #DFE3EF;
$colorGrey1: #edf0f7;
$colorGreyLight0: #F8F8F8;
$colorGreyLightBlue: #eef4f5;
$colorGreyLight1: #F9F9F9;
$colorGreyLight2: #F3F3F3;
$colorGreyLight3: #F0F0F0;
$colorGreyLight4: #E0E3E7;

$colorWhite: #FFF;
$colorMuted: #909FAF;
$colorBlack: #000000;
$colorBlackTransparent: #000000D9;
$colorBlackLight: #282930;
$colorBlackLighter: #707F8E70;
$colorBG: $colorBlack;

$colorYellowDark: #FFA72A;
$colorYellow: #FFB100;
$colorYellowLight: #FFD576;
$colorYellowLime: #b6be69;

$colorGreenDarker: #2AC19F7F;
$colorGreenDark: #2BA895;
$colorGreen: #2AC19F;
$colorGreenLight: #29C19E;
$colorGreenLighter: #A8D6CF;


$colorBlue: #48C0FF;
$colorBlueLight: #9BD8CF;
$colorBlueLighter: #89E5D1;
$colorBlueLightest: #9CE8D7;

$colorPink: #F9979A;
$colorPinkDarkest: #F28287;
$colorPinkDarker: #F88387;
$colorPinkDark: #FF8989;
$colorPinkLight: #FFC3C5;

$shadowColorLabelLight: $colorWhite;
$shadowColorLabelDark: #00000066;

$textColorLabelLight: $colorBlack;
$textColorLabelDark: #cccccc;

//Colors Ends

// Font Sizes Starts
$textBig: 18px;
$textExtraLarge: 16px;
$textLarge: 14px;
$textNormal: 12px;
$textSuper: 11px;
$textSmall: 10px;
$textExtraSmall: 9px;
$textTiny: 8px;
// Font Sizes Ends


// Space Starts
$spacingNone: 0px;
$spacingMicro: 2px;
$spacingTiny: 4px;
$spacingLargeTiny: 6px;
$spacingExtraSmall: 8px;
$spacingSmall: 10px;
$spacingMedium: 12px;
$spacingLessThanLarge: 14px;
$spacingLarge: 16px;
$spacingLarge-1: 18px;
$spacingExtraLarge: 20px;
$spacingExtraExtraLarge: 24px;
$spacingExtraExtraExtraLarge: 26px;
$spacingLessThanWide: 28px;
$spacingLessWide: 30px;
$spacingWide: 32px;
$spacingExtraWide: 34px;
$spacingUltraWide: 36px;
$spacingSmall4x: 40px;
$spacingExtended: 42px;
$spacingDouble: 48px;
$spacingExtraDouble: 60px;
// Space Ends

// Indoor Assets
$colorContainer: #2b2b2b;
$colorWall: #2a404b;
$colorFloor: #2a404b; // #595959
$colorText: #5d6975;
$colorOutlines: #5a5a5a;
$colorStairsOuter: $colorOutlines; //#3f444a;
$colorHighlight: #8d5818;

$paddingInline: $spacingWide;

.ant-layout-header.holotrak-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 16px;
    gap: 16px;
    padding: 0 $paddingInline;

    .holotrak-page-title {
        margin-bottom: 0;
    }

    .holotrak-table--search-controls {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .select-assets {
        width: 200px;
        padding: 5px 10px;
        height: 30px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #dbdfe3;
        border-radius: 25px;
        opacity: 1;
    }
    .ant-form-item .ant-form-item-label {
        flex-grow: 0;
        overflow: hidden;
        white-space: nowrap;
        text-align: start !important;
        vertical-align: middle;

        background-color: "red" !important;
    }
}

.search-device-activity {
    span.ant-input-affix-wrapper {
        border: 1px solid #efefef;
        background-color: transparent;
        border-radius: 16px !important;
        padding: 6px 30px 6px 15px;
        width: 259px;
        color: #000;
        font-weight: 500;
    }

    span.ant-input-group-addon {
        background: transparent;

        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            right: 0;
            background-color: transparent !important;
            border: none;
        }
    }

    .filter-support {
        &.open button {
            background: $colorGreen;
        }

        &.closed button {
            background: $colorGrey1;
        }
    }
}

.holotrak-paginated-list--content {
    padding: 0 $paddingInline;
    height: auto !important;
}

.holotrak-table {
    .ant-table-thead {
        .ant-table-cell {
            font-size: $textSmall;
            padding: $spacingLarge $spacingExtraSmall;
            font-weight: 700;
        }
    }

    .ant-table-tbody {
        .ant-table-row {
            &.disabled-row {
                cursor: not-allowed;
                opacity: 0.35;
            }

            .ant-table-cell {
                padding: $spacingLarge $spacingExtraSmall;
                font-weight: 500;

                &:first-child {
                    font-weight: 700;
                }
            }
        }
    }

    .ant-pagination {
        .ant-pagination-item {
            border-radius: 50%;
            border: 0;
            width: 32px;
            height: 32px;
            line-height: 32px !important;
            font-size: $textNormal;
            background-color: $colorWhite;

            a {
                color: $colorBlack;
            }

            &.ant-pagination-item-active {
                background-color: $colorBlack !important;

                a {
                    color: $colorWhite !important;
                }
            }
        }
    }
}

.ant-form-item .ant-form-item-label {
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: left !important;
    vertical-align: middle;
}
.ant-form-item {
    margin-bottom: 20px !important;
}
.add-title {
   
    text-align: left;
    font: normal normal medium 14px/16px SF Pro Display;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.custom-form-item {
    margin-bottom: 0px !important;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0 !important;
}
.ant-collapse{
    border-radius: 0px !important;
}
.ant-form-item .ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: start !important;
    vertical-align: middle;
}
.ant-collapse{
    border-radius: 0px;
}
.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
    padding: 0px;
}
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 0 0 0px 0px !important;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header{
    background: #EDF0F7 !important; 
}
[class^="ant-col"] [class^="ant-col"], [class*=" ant-col"] [class^="ant-col"], [class^="ant-col"] [class*=" ant-col"], [class*=" ant-col"] [class*=" ant-col"] {
    box-sizing: border-box;
    border-width: 1px 0px !important;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
   
    border-color: #4ecfac;
    background: #9CE8D7;
    color: black;
}
.ant-table-cell{
    vertical-align: top !important;
}
.ant-row-end {
    justify-content: center !important;
}
.ant-btn-primary{
    background-color: #9CE8D7 !important;
}
.ant-modal .ant-modal-content{
    border-radius: 0px !important;
}
.ant-form-item .ant-form-item-label >label::after {
    content: "";
}

.ant-select-single {
    font-size: 12px;
    width: 100%;
}
.ant-form-item-label > label[for="loggedBy"]::after,
.ant-form-item-label > label[for="task"]::after,
.ant-form-item-label > label[for="vehicle"]::after,
.ant-form-item-label > label[for="asset"]::after,
.ant-form-item-label > label[for="depot"]::after {
    content: ":";
}
